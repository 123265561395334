@import "modules/variables";

$navHighlight: #1fbad6;
$navDivider: #666;

/* UNBOUNCE HIDE THE CLOSE X AT TOP RIGHT*/
.ub-emb-iframe-wrapper .ub-emb-close {
	visibility: hidden;
}


.re-resaas-brand {

	&.backgroundsize.svg .re-page-logo {
		margin-left: -3px;
	}

	.re-page-navigation-logo {
		margin-left: -4px;
		text-indent: -9999em;
	}

	.re-skip-link {
		background: $navBackground;
		color: $navText;
	}

	.re-page-toolbar,
	.re-page-navigation-anonymous {
		background: $navBackground;
	}

	.re-page-navigation-control-link {
		border-color: $navText;
		color: $navText;
		background: $navHighlight;
	}

	.re-page-navigation-link {
		color: $navText;

		&:hover, &:focus {
			color: $navHighlight;
		}
	}

	.re-page-navigation-button {
		border-color: $navText;
	}

	.re-page-navigation-flyout {
		background: $navBackground;
	}

	.re-page-navigation-flyout-item {
		border-bottom-color: $navDivider;
	}

	.re-page-navigation-flyout-link {
		color: $navText;
	}
	//anonymous theme
	&.re-anonymous-theme {

		.re-page-outer-wrapper {
			box-sizing: border-box;
			min-height: 100vh;
		}

		.re-page-wrapper {
			box-sizing: border-box;
			color: $rGreyDarkest;
		}

		.re-page-toolbar,
		.re-page-navigation-anonymous {
			background: $navBackground;
		}
		// layoutcertificate
		.re-page-navigation {
			background: transparent;
		}
	}

	.re-sso-link-container {
		max-width: 1300px;

		.re-sso-link-label {
			display: block;
			margin: 40px auto 60px;
			color: $rGreyDarkest;
		}

		.re-sso-tile-container {
			display: flex;
			justify-content: space-between;

			@media #{$mediaquery-sm-max} {
				flex-direction: column;
			}

			.re-sso-tile {
				flex-basis: 11%; /* 100/9 = 11.111 */
				flex-grow: 0;

				@media #{$mediaquery-sm-max} {
					flex-basis: auto;
				}
			}
		}
	}


	.re-entry-login-container {
		@media #{$mediaquery-sm-max} {
			display: flex;
			flex-direction: column-reverse;
		}

		.re-entry-form-wrapper {
			width: 100%;
		}
	}

	.firm-logo-branded {
		max-width: 100%;
	}
}

.re-login-form-label {
	margin: $spacing-xl auto;
	color: $rGreyDarkest;
	text-align: center;
	font-size: $font-size-lg;
}

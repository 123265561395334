// colors
// ---------------------------------------------

$color-primary: #1fbad6;

$color-primary-highlight: mix($color-primary, #fff, 20%);

$color-primary-light: mix($color-primary, #fff, 60%);

$color-primary-dark: #03a3bf;

$color-primary-darker: #03879e;

$color-secondary: #56526E;

$color-grey: #6d6e71;

$color-grey-light: #cfcfcf;

$color-grey-xtralight: #f1f1f1;

$color-black: #464646;

$color-error: #ea4848;

$color-blue-dark: #4d637b;

$color-white: #ffffff;

$color-yellow: #ffd800;

$color-yellow-dark: #f1b500;

$color-purple: #685ca8;

$color-green: #45cb8b;

$color-green-light: mix($color-green, #fff, 60%);

$color-green-highlight: mix($color-green, #fff, 20%);

$color-green-dark: #449D4D;

$color-premarket-image-tint: rgba(42,42,42,0.4);

$color-gold: #C1A050;


//Begin legacy/taken from  old variables.scss
// resaas greys
// ---------------------------------------------
$rWhite: #FFF;
$rGreyLightest: #f9f9f9;
$rGreyLight: #eaeaea;
$rGrey: #cfcfcf;
$rGreyDark: #6d6e71;
$rGreyDarker: #505050;
$rGreyDarkest: #464646;
$rGreyLightTheme: #656565;
$rBlack: #000;

// resaas blues
// ---------------------------------------------
$rBlueLight: #0b88ca;
$rBlue: #0a6fb7;
$rBlueDark: #0961a8;

// resaas accents
// ---------------------------------------------
$rYellow: #fff200;

$rGreen: #49bc40;
$rGreenDark: #229c1f;
$rGreen2: #22c064;
$rGreen2Dark: #14a250;

$rRed: #d52736;
$rRedDark: #9d1b26;

// sfar color
//----------------------------------------------
$sfarPink: #f08080;
$sfarYellow: #e6c60d;

// keller Williams colors
//----------------------------------------------
$kwSecondaryColor: #2d859c;

// social media colors
$color-twitter: #1da1f3;
$color-facebook: #3b5998;
$color-linkedin: #0077B5;
$color-pinterest: #BD081C;
$color-instagram: #F10074;
$color-zillow: #006AFF;

//toolbar (anonymous nav) colors
$navText: #e3e3e3;
$navBackground: #464646;

//End Legacy/old variables.scss

// states
//----------------------------------------------
$hoverfocus: "&:hover, &:focus";

// shapes
// ---------------------------------------------

$border-radius-small: 3px;
$border-radius-large: 5px;
$border-radius-xl: 8px;

// spacing
// ---------------------------------------------
$spacing-sm: 10px;
$spacing-md: 15px;
$spacing-lg: 20px;
$spacing-xl: 40px;


// border styles
// ---------------------------------------------
$border-size: 1px;
$border-color: $rGreyLight;
$border: $border-size solid $border-color;

// box-shadow styles
// ---------------------------------------------
$box-shadow-outerContainer: 2px 2px 10px rgba($color-grey,0.1);
$box-shadow-dropDown: 0 5px 10px rgba($color-grey,0.5);

// typography
// ---------------------------------------------
$font-awesome: normal normal 14px / 1 FontAwesome;
$font-awesome-solid: normal normal 14px / 1 FontAwesomeSolid;

$font-family-sans: "Open Sans", Arial, Helvetica, sans-serif;
$font-family-serif: "Cambria", "Times New Roman", Georgia, serif;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 21px;
$font-size-xl: 36px;
$font-size-xxl: 48px;
// we would also like to add 30px. 
// Please include it when setting new font size variables

$font-size-body: $font-size-sm;

// icons
// ---------------------------------------------
$icon-size-xxs: $font-size-xxs;
$icon-size-xs: $font-size-sm;
$icon-size-sm: $font-size-md;
$icon-size-md: 18px;
$icon-size-lg: $font-size-lg;
$icon-size-xl: $font-size-xl;
$icon-size-xxl: $font-size-xxl;

// resaas web branding
// ---------------------------------------------
$baseColor: $rGreyDarkest;
$headingColor: #252525;
$linkColor: $color-primary-dark;
$linkColorDarker: $color-primary-darker;
$backgroundColor: $rGreyLight;

// resaas grid
// ---------------------------------------------

$grid-page-max-width: 1160px;

$grid-columns: 24;
$grid-gutter-width: 20px;
$grid-prefix: "re-grid";

$screen-xs-min: 20.625em;
$screen-sm-min: 26em;
$screen-md-min: 45em;
$screen-lg-min: 64em;

$screen-xxs-max: $screen-xs-min - 0.01em;
$screen-xs-max: $screen-sm-min - 0.01em;
$screen-sm-max: $screen-md-min - 0.01em;
$screen-md-max: $screen-lg-min - 0.01em;

$navigation-column-width: 225px;
$topnavheight: 50px;

// media queries
// ---------------------------------------------

$mediaquery-nav-full-min: "only screen and (min-width: 60.01em)"; // at least 960px
$mediaquery-nav-compact-max: "only screen and (max-width: 60em)"; // up to 960px

$mediaquery-xs-min: "only screen and (min-width:" + $screen-xs-min + ")"; //at least 330px
$mediaquery-sm-min: "only screen and (min-width:" + $screen-sm-min + ")"; //at least 416px
$mediaquery-md-min: "only screen and (min-width:" + $screen-md-min + ")"; //at least 720px
$mediaquery-lg-min: "only screen and (min-width:" + $screen-lg-min + ")"; //at least 1024px

$mediaquery-xxs-max: "only screen and (max-width:" + $screen-xxs-max + ")"; //up to 330px
$mediaquery-xs-max: "only screen and (max-width:" + $screen-xs-max + ")"; //up to 416px
$mediaquery-sm-max: "only screen and (max-width:" + $screen-sm-max + ")"; //up to 720px
$mediaquery-md-max: "only screen and (max-width:" + $screen-md-max + ")"; //up to 1024px



$mediaquery-dpi-md: "(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)";
$mediaquery-dpi-hi: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";

// page widths
// ---------------------------------------------

$pagewidth-a: 980px;
$pagewidth-b: 1290px;
$pagewidth-c: 925px;
$pagewidth-d: 1160px;
$pagewidth-e: 750px;
$pagewidth-f: 10000px;
$pagewidth-g: 800px;
$pagewidth-h: 1302px;
$pagewidth-i: 600px;
$pagewidth-j: 1150px;